import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

class FeatureTabs extends Component {
  state = {
    activeTab: 0
  }

  componentDidMount() {
    this.rotateInterval = setInterval(this.rotateTab, 5000);
  }

  componentWillUnmount() {
    if (this.rotateInterval) {
      clearInterval(this.rotateInterval);
    }
  }

  rotateTab = () => {
    const { data } = this.props;
    const maxIndex = (data?.tabs?.length || 1) - 1;
    this.setState(prevState => ({
      activeTab: prevState.activeTab >= maxIndex ? 0 : prevState.activeTab + 1
    }));
  }

  render() {
    const { data } = this.props;

    return (
      <div className="feature-tabs">
        <Nav pills>
          {data?.tabs.map((item, idx) => (
          <NavItem key={`nav-item-${idx}`}>
            <NavLink
              active={this.state.activeTab === idx}
              onClick={() => this.setState({ activeTab: idx })}
            >{item}</NavLink>
          </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={this.state.activeTab} className="position-relative">
          {data?.contents.map((item, idx) => (
          <TabPane 
            key={`tab-pane-${idx}`} 
            tabId={idx} 
            className={`tab-transition ${this.state.activeTab === idx ? 'opacity-100' : 'opacity-0'}`}
          >
            {item.description}
            <img src={item.img_url} alt="" className="img-fluid" />
          </TabPane>
          ))}
        </TabContent>
      </div>
    )
  }
}

export default FeatureTabs;